<template>
    <div class="viewer-wrapper">
        <header class="anim-view-header">
            <div class="home-btn control-btn" @click="handleHomeButton">
                <BaseIcon iconName="home"></BaseIcon>
            </div>

            <div class="logo">
                <img src="@/assets/logo.png" alt="Logo" class="logo" />
                <h1 class="header-1">AvaCapo</h1>
            </div>

            <div class="controls">
                <div class="control-btn locked" @click="handleSettings">
                    <BaseIcon   iconName="settings"
                                color="rgba(255,255,255,0.4)">
                    </BaseIcon>
                </div>
                <div class="control-btn" @click="handleDownloadIconClick">
                    <BaseIcon iconName="download"></BaseIcon>
                </div>

                <div v-if="showFormatDropdown" class="format-dropdown">
                    <select v-model="selectedFormat" @change="handleDownload">
                        <option value="glb">GLB</option>
                        <option value="fbx">FBX</option>
                    </select>
                </div>
            </div>
        </header>

        <div class="app-three-model-viewer">
            {{ animationName }}

            {{ isFavorite }}
            <ThreeModelViewer ref="threeModelViewer" :animationId="animationId"/>
        </div>
    </div>

</template>

<script setup>
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from "vuex";

import ThreeModelViewer from '../components/ThreeModelViewer.vue';
import BaseIcon from '@/components/BaseIcon.vue';

const route = useRoute();
const router = useRouter();
const store = useStore();


const animationId = route.query.animationId;
const animationName = route.query.animationName;
const isFavorite = route.query.isFavorite;
const sessionId = store.getters.getSessionId;

const threeModelViewer = ref(null);

const selectedFormat = ref("glb");
const showFormatDropdown = ref(false);

function handleDownloadIconClick() {
    showFormatDropdown.value = !showFormatDropdown.value; // Toggle dropdown visibility
}

function handleHomeButton() {
    router.push('/app/home');
}

function handleDownload() {
  downloadModelFile(selectedFormat.value);
  showFormatDropdown.value = false; 
}

async function downloadModelFile(extension) {
  const modelUrl = `${process.env.VUE_APP_BACKEND_URL}/download-animation/?hashed_id=${animationId}&extension=${extension}`;

  try {
    const response = await fetch(modelUrl, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'session-id': sessionId
      },
    });

    if (!response.ok) {
      throw new Error("Failed to download model file");
    }

    const blob = await response.blob();
    const downloadUrl = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = downloadUrl;
    link.download = `model.${extension}`; 

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(downloadUrl);
    
  } catch (error) {
    console.error("Error downloading model file:", error);
  }
}

</script>

<style scoped>
.viewer-wrapper {
    padding: 1%;
}

.anim-view-header {
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center
}

.controls {
    display: flex;
    align-items: center;
    justify-content: center
}

.locked {
  cursor: not-allowed;
}

.format-dropdown {
    margin-left: 10px;
    padding: 5px;
}

.format-dropdown select {
    padding: 5px;
}
</style>