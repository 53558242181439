<template>
  <div class="project-item" @click="goToAnimationDetail">
    <div class="favorite-btn" @click.stop="toggleFavorite">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        :class="{'filled': isFavoriteLocal, 'outlined': !isFavoriteLocal}"
      >
        <path
          d="M12 2L15.09 8.26L22 9.27L17 14.14L18.18 21.02L12 17.77L5.82 21.02L7 14.14L2 9.27L8.91 8.26L12 2Z"
          :fill="isFavoriteLocal ? '#FFD700' : 'none'"
          :stroke="isFavoriteLocal ? '#FFD700' : '#FFFFFF'"
          stroke-width="1.5"
        />
      </svg>
    </div>

    <div class="preview-wrapper">
      <img :src="finalImgUrl" :alt="imgName" class="preview-image" />
    </div>

    <div class="header-text caption">
      <div v-if="isEditingName">
        <input v-model="editedName" @keyup.enter="saveName" class="name-input" />
        <button @click.stop="saveName" class="save-btn">✔️</button>
        <button @click.stop="cancelEdit" class="cancel-btn">❌</button>
      </div>
      <div v-else>
        {{ displayedName }}
        <button @click.stop="editName" class="edit-btn">✏️</button>
      </div>
    </div>

    <div class="date-text caption">
      {{ relativeDate }}
    </div>
  </div>
</template>


<script setup>
import { useRouter } from 'vue-router';
import { computed, ref, onMounted } from 'vue';
import defaultImage from '@/assets/project-placeholder.png';

import { useStore } from "vuex";

const store = useStore();
const sessionId = store.getters.getSessionId;

const router = useRouter();

const props = defineProps({
  imgUrl: {
    type: String,
    default: defaultImage
  },
  imgName: String,
  imgDate: {
    type: String,
    required: true
  },
  id: String,
  isFavorite: {
    type: Boolean,
    default: false
  }
});

const finalImgUrl = computed(() => {
  return props.imgUrl || defaultImage;
});

const isEditingName = ref(false);
const editedName = ref(props.imgName);
const displayedName = ref(props.imgName); 

const isFavoriteLocal = ref(false);

onMounted(() => {
  isFavoriteLocal.value = props.isFavorite;
});

async function toggleFavorite() {
  try {
    const response = await fetch(`${process.env.VUE_APP_BACKEND_URL}/favorite`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'session-id': sessionId
      },
      body: JSON.stringify({ hashed_id: props.id })
    });

    if (!response.ok) {
      throw new Error('Failed to set favorite');
    }

    const result = await response.json();
    if (result.message) {
      isFavoriteLocal.value = !isFavoriteLocal.value;
      console.log('changed');
    } else {
      console.error('Failed to update favorite status');
    }
  } catch (error) {
    console.error('Error setting favorite:', error);
  }
}

function parseDateToUTC(dateString) {
  const [datePart, timePart] = dateString.split('T');
  const [year, month, day] = datePart.split('-').map(Number);
  const [hour, minute, second] = timePart.split(':').map(Number);
  return new Date(Date.UTC(year, month - 1, day, hour, minute, second));
}

const relativeDate = computed(() => {
  const nowUTC = new Date();

  if (props.imgDate === 'None') {
    return 'No date';
  }

  const imgDate = parseDateToUTC(props.imgDate);

  const diffTime = (nowUTC - imgDate) / 1000;
  const formatter = new Intl.RelativeTimeFormat('en', { numeric: 'auto' });

  if (diffTime < 60) {
    return formatter.format(-Math.round(diffTime), 'seconds');
  } else if (diffTime < 3600) {
    return formatter.format(-Math.round(diffTime / 60), 'minutes');
  } else if (diffTime < 86400) {
    return formatter.format(-Math.round(diffTime / 3600), 'hours');
  } else {
    return formatter.format(-Math.round(diffTime / 86400), 'days');
  }
});

const goToAnimationDetail = () => {
  if (!isEditingName.value) {
    router.push({ path: '/animation', query: { animationId: props.id, animationName: editedName.value, isFavorite: isFavoriteLocal.value } });
  }
};

function editName() {
  isEditingName.value = true;
}

function cancelEdit() {
  isEditingName.value = false;
  editedName.value = props.imgName;
}

async function saveName() {
  try {
    const response = await fetch(`${process.env.VUE_APP_BACKEND_URL}/change_animation_name`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'session-id': sessionId
      },
      body: JSON.stringify({ hashed_id: props.id, new_name: editedName.value })
    });

    if (!response.ok) {
      throw new Error('Failed to update animation name');
    }

    const result = await response.json();
    if (result) {
      displayedName.value = editedName.value;
      isEditingName.value = false;
    } else {
      console.error('Failed to update animation name');
    }
  } catch (error) {
    console.error('Error updating name:', error);
  }
}
</script>


<style scoped>
.project-item {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-right: .8rem;
  cursor: pointer;
  flex: 0 0 18%;
  width: 100%;
  margin-bottom: 10px;
}

.preview-wrapper {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  padding: 10px 25px;
  margin-bottom: 20px;
  box-sizing: border-box;
}

.preview-image {
  max-width: 100%;
  height: auto;
  border-radius: 4px;
}

.header-text {
  text-align: left;
}

.date-text {
  color: rgba(255, 255, 255, 0.2);
  text-align: left;
}

.favorite-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  display: none;
}

.project-item:hover .favorite-btn {
  display: block;
}

svg.filled path {
  fill: #FFD700;
  stroke: #FFD700;
}

svg.outlined path {
  fill: none;
  stroke: #FFFFFF;
}

.name-input {
  width: 60%;
  background: transparent;
  color: white;
  border: none;
}

.edit-btn {
  float: right;
}

@media (max-width: 768px) {
  .project-item {
    flex: 0 0 45%;
    margin-right: 10px;
    margin-bottom: 20px;
  }

  .preview-wrapper {
    padding: 15px;
    margin-bottom: 15px;
  }

  .header-text,
  .date-text {
    font-size: 1.2rem;
  }
}
</style>
